<template>
  <div v-if="test" class="dumb">
    <div>{{ componentType }} block - {{ title }}</div>
    <pre>{{ $attrs }}</pre>
  </div>
  <div v-else class="dumb"></div>
</template>

<script setup>
// import { useAttrs } from 'vue'
const attrs = useAttrs();
const props = defineProps({
  title: { type: String, default: "No title" },
});
const componentType = attrs?.type || "No type";
const test = process.env.NODE_ENV === "development";
</script>
